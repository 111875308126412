import * as React from 'react';
import Button from '@mui/material/Button';
import { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { storage } from './firebase';
import { ref, getDownloadURL, uploadBytes, listAll } from "firebase/storage";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function MyApp() {
  const [isLoading, setIsLoading] = useState(false)
  const [files, setFiles] = useState();
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState('')
  const [imageOpen, setImageOpen] = useState(false)


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleImageOpen = async (url) => {
    setImage(url)
    setImageOpen(true)
  }

  const handleImageClose = (event, reason) => {
    setImageOpen(false)
  }


  const handleFileSelect = async (e) => {
    const files = e.target?.files
    if (!files) return;
    //    const storageRef = ref(storage, `photos/${file.name}`);
    //const uploadTask = uploadBytesResumable(storageRef, file);

    const promises = [];
    for (var i = 0; i < files.length; i++) {
      const storageRef = ref(storage, `photos/${files[i].name}`);
      promises.push(uploadBytes(storageRef, files[i]));

    }
    setIsLoading(true)
    await Promise.all(promises);
    await loadImages()
    setIsLoading(false)
    setOpen(true);
  };
  const fetchImages = async () => {
    let result = await listAll(ref(storage, '/photos/thumbnails/'));
    let urlPromises = result.items.map((imageRef) =>
      getDownloadURL(imageRef)
    );

    return Promise.all(urlPromises);
  };

  const loadImages = async () => {
    const urls = await fetchImages();
    setFiles(urls);
  };


  useEffect(() => {
    const fetchImages = async () => {
      let result = await listAll(ref(storage, '/photos/thumbnails/'));
      let urlPromises = result.items.map((imageRef) =>
        getDownloadURL(imageRef)
      );

      return Promise.all(urlPromises);
    };

    const loadImages = async () => {
      const urls = await fetchImages();
      setFiles(urls);
    };
    loadImages();
  }, []);

  return (
    <div>
      <Grid container paddingTop={5} spacing={2} direction="column" alignItems="center" justifyContent="start">
        <Grid item xs={10}>
          <Typography variant={'h4'} align='center'>Meg and Tom</Typography>
          <Typography variant={'subtitle1'} align='center'>Wedding Photos</Typography>
        </Grid>
        <Grid item xs={10}>
          {isLoading ? <CircularProgress /> : <Button variant="contained" component="label">
            Upload Photos
            <input hidden accept="image/*" multiple type="file" onChange={handleFileSelect} />
          </Button>}
        </Grid>
        <Grid item>
          {files === undefined ? <CircularProgress /> : <ImageList variant="masonry" sx={{ paddingLeft: '2vw', paddingRight: '2vw' }} cols={3}>
            {files?.map((file) => (
              <ImageListItem key={file} onClick={(e) => { handleImageOpen(e.target.attributes.src.nodeValue) }}>
                <img
                  src={`${file}`}
                  alt='tom and megs wedding'
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>}
        </Grid>
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Your photos were added successfully, thanks!
        </Alert>
      </Snackbar>

      <Dialog
        open={imageOpen}
        TransitionComponent={Transition}
        keepMounted
        maxWidth={'90vw'}
        onClose={handleImageClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <img
            src={`${image}`}
            alt='tom and megs wedding'
            sx={{maxWidth: '100%', maxHeight:'auto'}}
            loading="lazy"
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
