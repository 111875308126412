// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCf1cteBCa1itIDi_WFVEzRiz-wVse6Oaw",
    authDomain: "wedding-photos-ca864.firebaseapp.com",
    projectId: "wedding-photos-ca864",
    storageBucket: "wedding-photos-ca864.appspot.com",
    messagingSenderId: "898526914399",
    appId: "1:898526914399:web:a22ef728f2260ca327f8ea"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);